import React from 'react';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

const SectionPortfolio = ({ img, appcat, appname, applink, title, desc }) => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font px-5 mb-8 bg-blue-50">
      <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img src={img} className="object-cover object-center rounded" alt="" />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <p className="leading-relaxed uppercase">{appcat}</p>
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-900">{title}</h1>
          <p className="mb-8 leading-relaxed text-xl">{desc}</p>
          <Link className="btn" to={applink}>{t('portfolio.findoutmore')} {appname}</Link>
        </div>
      </div>
    </section>
  );
};

export default SectionPortfolio