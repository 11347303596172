import * as React from "react";
import {graphql} from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import SolutionsHero from '../components/SolutionsHero';
import SolutionsFeature from '../components/SolutionsFeature';
import SolutionsExample from '../components/SolutionsExample';
import SectionStatistics from "../components/SectionStatistics";
import SectionPortfolio from "../components/SectionPortfolio";
import SectionTestimonial from "../components/SectionTestimonial";
import SectionCTA from '../components/SectionCTA';
import imgPortfolio1 from '../images/portfolio/mixercms/mixercms4.png'
import {useTranslation} from 'gatsby-plugin-react-i18next';

// markup
const SolutionsPage = () => {
  const {t} = useTranslation();
  return (
    <Layout>
      <Seo />
      <SolutionsHero />
      <SolutionsFeature />
      <SolutionsExample />
      <SectionStatistics />
      <SectionPortfolio 
        img={imgPortfolio1}
        appcat={t('mixercms.tag')}
        appname="Mixer CMS"
        applink="/dx/mixercms"
        title={t('mixercms.title')}
        desc={t('mixercms.desc')} 
      />
      <SectionTestimonial />
      <SectionCTA />
    </Layout>
  )
};

export default SolutionsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "solutions"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;