import React from 'react';
// import {StaticImage} from "gatsby-plugin-image"
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const SolutionsExample = () => {
  const {t} = useTranslation();
  return(
    <section className="text-gray-600 body-font py-16 mt-2 mb-8 bg-whatcandocomplex">
      <div className="my-cont">
        <Divider title={t('example.title')} />
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-300 flex-shrink-0">
            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 512 512">
              <path d="m433 512c-11.046 0-20-8.954-20-20 0-78.299-63.701-142-142-142h-30c-78.299 0-142 63.701-142 142 0 11.046-8.954 20-20 20s-20-8.954-20-20c0-100.355 81.645-182 182-182h30c100.355 0 182 81.645 182 182 0 11.046-8.954 20-20 20zm-44-377c0-74.439-60.561-135-135-135s-135 60.561-135 135 60.561 135 135 135 135-60.561 135-135zm-40 0c0 52.383-42.617 95-95 95s-95-42.617-95-95 42.617-95 95-95 95 42.617 95 95zm-84.146 374.403 83-47c4.764-2.697 8.213-7.229 9.545-12.538 1.331-5.309.429-10.932-2.498-15.558l-31-49c-5.904-9.334-18.261-12.115-27.594-6.208-9.335 5.905-12.114 18.26-6.209 27.594l19.771 31.252-54.749 31.002-53.371-30.969 19.307-31.536c5.768-9.42 2.807-21.732-6.613-27.5-9.42-5.767-21.733-2.806-27.5 6.614l-30 49c-2.813 4.593-3.653 10.127-2.332 15.349s4.692 9.69 9.352 12.393l81 47c3.103 1.8 6.569 2.701 10.037 2.701 3.397.001 6.797-.864 9.854-2.596z"/>
            </svg>
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-blue-900 text-lg title-font font-medium mb-2">{t('example1.title')}</h2>
            <p className="leading-relaxed text-base">{t('example1.desc')}</p>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-blue-900 text-lg title-font font-medium mb-2">{t('example2.title')}</h2>
            <p className="leading-relaxed text-base">{t('example2.desc')}</p>
          </div>
          <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-300 flex-shrink-0">
            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 512 512">
              <path d="m464.216 405.708c-48.055 66.557-125.893 106.292-208.216 106.292-68.38 0-132.667-26.629-181.02-74.98-48.351-48.352-74.98-112.64-74.98-181.02s26.629-132.667 74.98-181.02c48.353-48.351 112.64-74.98 181.02-74.98 82.323 0 160.161 39.735 208.216 106.292 6.465 8.956 4.447 21.457-4.509 27.923-8.954 6.466-21.456 4.447-27.923-4.508-40.555-56.171-106.269-89.707-175.784-89.707-119.103 0-216 96.898-216 216s96.897 216 216 216c69.515 0 135.229-33.536 175.784-89.708 6.467-8.955 18.969-10.974 27.923-4.508 8.956 6.467 10.974 18.968 4.509 27.924zm-208.216-244.708c25.555 0 49.574 10.269 67.634 28.915 7.685 7.934 20.347 8.136 28.281.452 7.934-7.685 8.136-20.347.451-28.281-25.662-26.495-59.886-41.086-96.366-41.086-74.439 0-135 60.561-135 135s60.561 135 135 135c36.481 0 70.705-14.591 96.366-41.085 7.685-7.934 7.482-20.596-.451-28.281-7.936-7.685-20.596-7.482-28.281.452-18.06 18.645-42.079 28.914-67.634 28.914-52.383 0-95-42.617-95-95s42.617-95 95-95zm226.485 115.34c-5.471-5.44-8.485-12.663-8.485-20.34s3.014-14.9 8.485-20.34l23.615-23.476c7.833-7.788 7.871-20.451.083-28.284-7.788-7.835-20.453-7.871-28.284-.084l-23.615 23.477c-8.193 8.145-14.079 17.976-17.335 28.708h-180.949c-11.046 0-20 8.954-20 20s8.954 20 20 20h180.949c3.256 10.732 9.142 20.563 17.335 28.708l23.615 23.476c3.903 3.878 9.001 5.815 14.101 5.815 5.138 0 10.275-1.968 14.184-5.9 7.788-7.833 7.75-20.497-.083-28.284z"/>
            </svg>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
          <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-300 flex-shrink-0">
            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 512 512">
              <path d="m438 0c-40.804 0-74 33.196-74 74v364c0 40.804 33.196 74 74 74s74-33.196 74-74v-364c0-40.804-33.196-74-74-74zm34 438c0 18.748-15.252 34-34 34s-34-15.252-34-34v-364c0-18.748 15.252-34 34-34s34 15.252 34 34zm-398-156c-40.804 0-74 33.196-74 74v82c0 40.804 33.196 74 74 74s74-33.196 74-74v-82c0-40.804-33.196-74-74-74zm34 156c0 18.748-15.252 34-34 34s-34-15.252-34-34v-82c0-18.748 15.252-34 34-34s34 15.252 34 34zm148-299c-40.804 0-74 33.196-74 74v225c0 40.804 33.196 74 74 74s74-33.196 74-74v-225c0-40.804-33.196-74-74-74zm34 299c0 18.748-15.252 34-34 34s-34-15.252-34-34v-225c0-18.748 15.252-34 34-34s34 15.252 34 34zm-278.585-257.721c4.079-10.265 15.708-15.277 25.973-11.198 18.178 7.227 36.603 9.919 43.945 9.919 9.412 0 17.667-7.788 17.667-16.667 0-9.034-10.049-16.666-21.944-16.666-37.088 0-65.056-24.361-65.056-56.667 0-27.295 19.342-50.142 45-55.484v-13.516c0-11.046 8.954-20 20-20s20 8.954 20 20v15.411c7.607 1.667 16.265 4.2 26.193 8.061 10.294 4.003 15.395 15.594 11.391 25.889-4.003 10.293-15.595 15.395-25.889 11.391-19.075-7.419-31.023-8.419-40.195-8.419-9.098 0-16.5 7.477-16.5 16.667 0 9.657 10.538 16.667 25.056 16.667 34.156 0 61.944 25.42 61.944 56.666 0 15.218-6.166 29.54-17.361 40.329-7.043 6.787-15.494 11.597-24.639 14.169v11.169c0 11.046-8.954 20-20 20s-20-8.954-20-20v-11.778c-10.723-2.082-22.805-5.366-34.387-9.969-10.265-4.08-15.279-15.709-11.198-25.974z"/>
            </svg>
          </div>
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-blue-900 text-lg title-font font-medium mb-2">{t('example3.title')}</h2>
            <p className="leading-relaxed text-base">{t('example3.desc')}</p>
          </div>
        </div>
        <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
          <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
            <h2 className="text-blue-900 text-lg title-font font-medium mb-2">{t('example4.title')}</h2>
            <p className="leading-relaxed text-base">{t('example4.desc')}</p>
          </div>
          <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-yellow-100 text-yellow-300 flex-shrink-0">
            <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 512 512">
              <g><path d="m236.029 124v42c0 11.046 8.954 20 20 20s20-8.954 20-20v-62c0-11.046-8.954-20-20-20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20z"/><path d="m256.029 270c74.439 0 135-60.561 135-135s-60.561-135-135-135-135 60.561-135 135 60.56 135 135 135zm0-230c52.383 0 95 42.617 95 95s-42.617 95-95 95-95-42.617-95-95 42.617-95 95-95z"/><path d="m505.803 381.875c-13.039-25.05-42.723-35.939-70.356-25.454-19.27 7.314-42.832 16.254-68.478 23.326 2.366-11.388 1.186-23.447-3.544-34.912-6.896-16.716-21.069-29.153-38.884-34.122-9.106-2.54-18.992-3.112-29.385-1.699-68.528 9.321-109.325-13.851-145.323-34.294-24.197-13.741-47.05-26.72-73.804-26.72-34 0-61.974 10.913-61.974 10.913-10.542 3.299-16.412 14.519-13.113 25.061 3.299 10.541 14.517 16.415 25.061 13.113 0 0 20.026-9.087 50.026-9.087 16.188 0 32.896 9.489 54.051 21.502 31.676 17.988 73.266 41.611 135.088 41.609 11.116 0 22.895-.765 35.378-2.462 4.89-.665 9.473-.46 13.249.593 4.216 1.176 9.833 4.013 12.654 10.848 2.188 5.302 2.297 10.263.324 14.746-2.419 5.499-7.933 10.056-15.522 12.831-10.955 4.005-26.494 6.139-44.222 6.333-19.013.14-37.59-2.053-53.917-6.343-10.683-2.808-21.62 3.578-24.427 14.261s3.578 21.619 14.261 24.426c19.119 5.024 42.248 7.657 64.083 7.657v-.016c75.998-.341 141.141-24.425 182.61-40.166 6.952-2.637 16.506-1.502 20.697 6.553 3.752 7.208.99 16.179-6.157 19.998-50.195 26.815-108.444 51.63-194.038 51.63-76.199 0-146.387-29.405-191.849-54.072-35.021-19.002-60.613-13-63.415-12.253l.024.09c-8.557 2.267-14.873 10.042-14.873 19.312 0 11.046 8.954 20 20 20 1.821 0 3.577-.265 5.254-.721 2.938-.398 15.475-1.286 33.934 8.73 49.533 26.876 126.334 58.914 210.925 58.914 107.407 0 178.649-38.06 212.886-56.35 26.394-14.101 36.618-47.185 22.776-73.775z"/></g>
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionsExample;
